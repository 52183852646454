import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

export default function(){
    return new VueI18n({
        locale: 'bg_BG' // set locale
        , messages: {
            bg_BG: {
                message: {
                    basket: 'КОШНИЦА'
                    , price: '{price1},{price2} лв.'
                    , total: 'ОБЩО'
                    , see_basket: 'Виж кошницата'
                    , empty_basket: 'Няма добавени книги в кошницата'
                }
            }
            , en_EN: {
                message: {
                  basket: 'BASKET'
                  , price: '€ {price1}.{price2}'
                  , total: 'TOTAL COST'
                  , see_basket: 'Basket / checkout'
                  , empty_basket: 'Your basket is empty'
                }
            }
        }
    })
}