import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import i18n from './i18n'

if (!String.prototype.padStart) {
    String.prototype.padStart = function padStart(targetLength,padString) {
        targetLength = targetLength>>0; //truncate if number or convert non-number to 0;
        padString = String((typeof padString !== 'undefined' ? padString : ' '));
        if (this.length > targetLength) {
            return String(this);
        }
        else {
            targetLength = targetLength-this.length;
            if (targetLength > padString.length) {
                padString += padString.repeat(targetLength/padString.length); //append to original to ensure we are longer than needed
            }
            return padString.slice(0,targetLength) + String(this);
        }
    };
}

Vue.use(VueAxios, axios)

Vue.config.productionTip = false
axios.defaults.baseURL = '/api'
axios.defaults.headers['X-Requested-With']  = 'XMLHttpRequest'
axios.defaults.xsrfCookieName = 'BOOKSTORE_SESSION'
axios.defaults.withCredentials = true
window.shoppingBasket = new Vue({
  mounted(){
    this.app = this.$children[0]

  }
  , methods: {
    addToBasket(id){
      return this.app.addToBasket(id)
    }
    , removeFromBasket(id){
      return this.app.removeFromBasket(id)
    }
    , hasItem(bookitemId) {
      return this.app.hasItem(bookitemId)
    }
    , onDataLoaded(f) {
        this.app.$on("dataLoaded", f)
    }
  }
  , i18n: i18n()
  , data: {
    app: undefined
  }
  , render: h => h(App)
}).$mount('#shoppingBasket')
