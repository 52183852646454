<template>
  <a href="/basket" :class="{basket: true, busy: this.busy}" style="text-decoration: none;">
    <span id="basketCaption">{{ $t('message.basket') }} /
      {{ $t('message.price', {price1: Math.floor(orderTotal / 100), price2: (orderTotal % 100).toString().padStart(2, '0')}) }}</span>
    <i :data-badge="bookitems.length" class="material-icons material-icons mdl-badge--overlap mdl-badge">shopping_basket</i>
    <div id="basketDropdown" v-if="bookitems.length > 0">
      <ul>
        <li :key="bi.bookItemId" v-for="bi in bookitems">
          <img v-if="bi.cover" :src="bi.cover">
          <i v-if="!bi.cover" class="material-icons missing-cover">import_contacts</i>
          <div>
            <p class="grow title">{{ bi.title + (bi.subtitle ? ': ' + bi.subtitle : '')  }}</p>
            <p class="grow author">{{ bi.authorsNames }}</p>
            <div class="price">1 x {{ $t('message.price', {price1: Math.floor(bi.price / 100), price2: (bi.price % 100).toString().padStart(2, '0')}) }}</div>
          </div>
        </li>
      </ul>
      <div class="basket-total">{{ $t('message.total') }} <span>{{ $t('message.price', {price1: Math.floor(orderTotal / 100), price2: (orderTotal % 100).toString().padStart(2, '0')}) }}</span></div>
      <div class="basket-actions">
        <button class="grow mdl-button mdl-js-button mdl-button--raised mdl-button--accent">
          {{ $t('message.see_basket') }}
        </button>
      </div>
    </div>
    <div id="basketDropdown" v-if="bookitems.length == 0">
      <p>{{ $t('message.empty_basket') }}</p>
    </div>
  </a>
</template>
<script>
export default {
  name: 'app'
  , created() {
      this.axios.get('basket').then(response => {
          this.initialLoading = false
          this.bookitems = response.data
          this.$emit("dataLoaded", response.data)
      })
    }
  , methods: {
      addToBasket(bookItemId) {
          this.busy = true
          if(bookItemId == null) bookItemId = this.bookItemId
          return this.axios.put('basket/'+bookItemId).then(response => {
              this.bookitems = response.data
              this.busy = false
              return response.data
          }).catch(error => {
              this.busy = false
              return error
          })
      }
      , removeFromBasket(bookItemId) {
          this.busy = true
          if(bookItemId == null) bookItemId = this.bookItemId
          return this.axios.delete('basket/'+bookItemId).then(response => {
              this.bookitems = response.data
              this.busy = false
              return response.data
          }).catch(error => {
              this.busy = false
              console.log(error)
              return error
          })
      }
      , hasItem(bookItemId) {
         return (Array.from(this.bookitems).find(el => el.bookItemId == bookItemId) != undefined)
      }
    }
  , data: () => {
      /*[{
        "bookItemId":37,
        "title":"Калифорнийски разкази",
        "subtitle":"том 1",
        "price":10,
        "authorsNames":"Бари Късел, Франсис Брет Харт",
        "cover":"asdasd/f431b745-e7df-45ca-9ec5-aff560edae54"}
        ]*/
        return ({
            bookitems: []
            , bookItemId: ''
            , initialLoading: true
            , busy: false
    })
  }
  , computed: {
      orderTotal: function () {
          if(this.initialLoading) return ""
          return Array.from(this.bookitems).reduce((sum, bi) => {
              return sum + bi.price
          }, 0)
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>